// require("company_custom/pigeonhole")
// require("company_custom/webcast_QA_01")
// require("company_custom/webcast_QA_02")

document.addEventListener("turbolinks:load", () => {
  $("input.option_CDP").attr("disabled", "disabled");
  $("input.option_CDP").attr("maxlength", 12);
  $("input.option_CDP").attr("minlength", 12);
  $('input[type=radio][name="registration[registration_responses[5]]"]').change(function() {
    var textSelected = this.value;

    if(textSelected == 'CDP Securities Account. Please enter your account number below.'){    
      $("input.option_CDP").attr("required", "required");
      $("input.option_CDP").removeAttr("disabled");
    }else{
      $("input.option_CDP").removeAttr("required");
      $("input.option_CDP").attr("disabled", "disabled");
    }
  });
  // Restricts input for each element in the set of matched elements to the given inputFilter.
  (function($) {
    $.fn.inputFilter = function(inputFilter) {
      return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    };
  }(jQuery));

  $("input.option_CDP").inputFilter(function(value) {
    return /^\d*$/.test(value); 
  });

  // required checkboxes to have at least 1 option checked
  var requiredCheckboxes = $('.form-check-input:checkbox[required]');
  requiredCheckboxes.change(function(){
      if(requiredCheckboxes.is(':checked')) {
          requiredCheckboxes.removeAttr('required');
      } else {
          requiredCheckboxes.attr('required', 'required');
      }
  });
})


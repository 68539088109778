import axios from 'axios'

const csrfToken = document.querySelector("meta[name=csrf-token]").content
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

var OtpAuth;
var OtpTimerinterval;
if (typeof OtpAuth === 'undefined') OtpAuth = {};

const form = document.querySelector('#otpAuthForm')
const email = document.querySelector('#otpAuthEmail')
const otpField = document.querySelector('#otpAuthOtp')
const otpSentText = document.querySelector('.otp-sent-text')
const otpToEmail = document.querySelector('.otp-to-email')
const note = document.querySelector('.note')
const helpContent = document.querySelector('#login_help_content')
const resendOTP = document.querySelector('#resendOTP')

OtpAuth = {
  timerState: false,
  emailState: '',
  init: () => {
    email.onfocus = () => {
      OtpAuth.showSubmitButton()
      OtpAuth.clearError()
    }
    otpField.onfocus = () => {
      OtpAuth.showSubmitButton()
    }
    OtpAuth.switchForm('email') // Set default view
    OtpAuth.submit() // Listen on form submit
    OtpAuth.resendOTP() // Listen resendOTP 
  },

  getOTP: (formData) => {
    let dataObj = {
        method: 'POST',
        url: '/send_otp',
        data: formData
    }

    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data.status == 'ok' && response.data.email === OtpAuth.emailState.toLowerCase()) {
          let codeDate = new Date(response.data.time);
          let curDate = new Date(Date.now())
          let diff = codeDate.getTime() - curDate.getTime()
          OtpAuth.switchForm('otp') // show OTP input
          OtpAuth.setTimer(diff/1000) // start timer
        } else if (response.status == 200 && response.data.status == 'fail_email'){
          OtpAuth.switchForm('email')
          OtpAuth.setError(response.data.error || 'No response from server', 10000)
        }else{
          OtpAuth.switchForm('email')
          OtpAuth.setError(response.data.error || 'No response from server')
        }
      })
      .catch(error => console.log('catch',error))
  },

  verifyOTP: (formData) => {
    let dataObj = {
        method: 'POST',
        url: '/verify_otp',
        data: formData
      }
    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data.status == 'ok') {
          window.location.href = window.location.pathname + window.location.search;
        } else if (response.status == 200 && response.data.status == 'fail' && response.data.verifyed) {
          OtpAuth.setError(response.data.error)
          setInterval(function () { window.location.href = '/sign_in'}, 5000);
        }else{
          OtpAuth.setError(response.data.error || 'No response from server')
        }
      })
      .catch(error => console.error('catch',error))
  },

  setTimer: (duration) => {
    let timer = duration, minutes, seconds;
    let elem = document.querySelector('#timer')

    if (OtpAuth.timerState === true) {
      clearInterval(OtpTimerinterval);
      OtpTimerinterval = null;
      elem.innerHTML = ''
    }
    
    if (!OtpTimerinterval) {
      OtpTimerinterval = setInterval(() => {
        OtpAuth.timerState = true
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        elem.textContent = minutes + ":" + seconds; // set time

        if (--timer < 0) {
          clearInterval(OtpTimerinterval)
          OtpTimerinterval = null;
          elem.innerHTML = '' // clear timer element
          OtpAuth.switchForm('email')
        }
      }, 1000);
    }

  },

  switchForm: (view) => {
    let inputs = form.elements
    if(view === 'email') {
      otpSentText.style.display = 'none' // hide header above otp input box
      otpField.style.display = 'none' // hide otp box
      email.style.display = 'block'
      inputs['commit'].className = inputs['commit'].className.replace(/btn\-success/g, "btn-primary")
      inputs['commit'].value = 'Get OTP to log in'
      inputs['commit'].dataset.disableWith = inputs['commit'].value
      note.style.display = 'none' // hide notes
      if (resendOTP) {
        resendOTP.style.display = 'none' // hide resendOTP 
      }
      OtpAuth.timerState = false
    }else if(view === 'otp') {
      otpField.style.display = 'block' // show otp box
      otpField.value = '' // clear otp field
      otpToEmail.innerHTML = OtpAuth.emailState
      email.style.display = 'none'
      note.style.display = 'block' // show notes  
      if (helpContent){
        helpContent.style.display = 'none' // hide helpContent
      }
      if (resendOTP) {
        resendOTP.style.display = 'block' // show resendOTP 
      }  
      otpSentText.style.display = 'block' // show header above otp input box
      inputs['commit'].className = inputs['commit'].className.replace(/btn\-primary/g, "btn-success")
      inputs['commit'].value = 'Continue'
    }else{
      console.error('Something wrong with switchForm method, view not passed')
    }
  },

  showSubmitButton: () => {
    //OtpAuth.setError('')
    let inputs = form.elements
    inputs['commit'].disabled = false
  },

  validation: (field, type) => {
    let valid = true
    try {
      if(!field || field.length <= 0) {
        valid = false
        OtpAuth.setError('Please Fill out This Field')
        if(type == 'email'){
          OtpAuth.switchForm('email')
        }
      }else if(type == 'email') {
        let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(pattern.test(String(field).toLowerCase()) === false) {
          valid = false
          OtpAuth.setError('Email should be valid')
          OtpAuth.switchForm('email')
        }
      }else if(type == 'otp') {
        let pattern = /^[0-9]{1,6}$/
        if(pattern.test(field) === false) {
          valid = false
          OtpAuth.setError('Code should be 6 digits long')
        }
      }
    } catch (e) {
      console.error(e)
      valid = false
    }
    return valid
  },

  setError: (msg, timeout = 5000) => {

    OtpAuth.createErrorElement(msg, 'alert-danger')

		window.setTimeout(function() {
      OtpAuth.clearError()
    }, timeout)

  },

  clearError: () => {
    let toRemove = document.querySelector('#notice')
    if(toRemove) {
      toRemove.parentNode.removeChild(toRemove);
    }
  },

  submit: () => {
    form.addEventListener("submit", (e) => {
      e.preventDefault()

      var formData = new FormData(form)

      if(OtpAuth.timerState === true) {
        let otp = formData.get('otp')
        if(OtpAuth.validation(otp,'otp')) {
          OtpAuth.verifyOTP({ 'otp': otp, 'email': OtpAuth.emailState})
        }
      }else{
        let email = formData.get('user[email]')
        if(OtpAuth.validation(email,'email')) {
          OtpAuth.getOTP({'email': email})
          OtpAuth.emailState = email
        }
      }
    }, false);
    
  },

  createErrorElement: (msg, type) => {
    var body = document.querySelector('body')
    var notice = document.createElement('div')
    var alert = document.createElement('div')
    var icon = document.createElement('i')
    var button = document.createElement('button')
    var span = document.createElement('span')
    var text = document.createElement('span')

    notice.style.display = 'block'
    notice.setAttribute('id', 'notice')
    alert.setAttribute('role', 'alert')
    alert.className = 'alert '+type+' alert-dismissible fade show'
    icon.className = 'fas fa-exclamation-triangle'
    button.setAttribute('type', 'button')
    button.setAttribute('data-dismiss', 'alert')
    button.setAttribute('area-label', 'Close')
    button.className = 'close'
    span.setAttribute('aria-hidden','true')
    span.innerHTML = '&times;'
    text.innerHTML = msg

    body.appendChild(notice)
    notice.appendChild(alert)
    alert.appendChild(icon)
    alert.appendChild(text)
    alert.appendChild(button)
    button.appendChild(span)
  },

  resendOTP: () =>{
    resendOTP.addEventListener("click", (e) => {
      e.preventDefault();
      var formData = new FormData(form)
      let email = formData.get('user[email]')
      OtpAuth.getOTP({ 'email': email })
      OtpAuth.emailState = email

    });
  },
}

document.addEventListener("turbolinks:load", () => {
  if(form) {
    OtpAuth.init()
  }
})

document.addEventListener("turbolinks:load", () => {
  //get all selected checkbox registrants id
  function sumRegistrants() {
    var registrants = [];
    $('[name="registrant_id"]').each(function () {
      if ($(this).is(":checked")) {
        registrants.push($(this).val());
      }
    });
    return registrants;
  }

  //disable/active batch button
  function batchBtnState() {
    var $checked = $('input[name="registrant_id"]:checked');
    var $countChecked = $('input[name="registrant_id"]:checked').length;
    if ($countChecked > 0) {
      var webcastBtn = true;
      $checked.each(function (e) {
        var status = $(this).data('status');
        if (status != 'rejected' && status != 'approved') {
          $("#batch_send_webcast").addClass("disabled");
          $("#batch_send_webcast").attr("disabled", "disabled");
          webcastBtn = false;
          return false;
        }
      });
      if (webcastBtn == true) {
        $("#batch_send_webcast").removeClass("disabled");
        $('#batch_send_webcast').removeAttr("disabled");
      }
      $("#batch_approve").removeClass("disabled");
      $("#batch_reject").removeClass("disabled");
    } else {
      $(".batch_btn").addClass("disabled");
    }
  }

  // batch button - approve 
  $("#batch_approve").click(function (e) {
    var event_id = $(this).data('event-id');
    var registrants = sumRegistrants();
    if (registrants.length > 0) {
      if (confirm("Are you sure you want to approve the selected " + registrants.length + " registrant(s)? ")) {
        $.ajax({
          url: '/events/' + event_id + '/registrations/approve_registrations',
          type: "POST",
          data: {registrants},
          success: function (msg) {}
        });
      }
    }
  });

  // batch button - reject
  $("#batch_reject").click(function (e) {
    var event_id = $(this).data('event-id');
    var registrants = sumRegistrants();
    if (registrants.length > 0) {
      if (confirm("Are you sure you want to reject the selected " + registrants.length + " registrant(s)? ")) {
        $.ajax({
          url: '/events/' + event_id + '/registrations/reject_registrations',
          type: "POST",
          data: {registrants},
          success: function (msg) {}
        });
      }
    }
  });

  // batch button - send webcast link 
  $("#batch_send_webcast").click(function (e) {
    var event_id = $(this).data('event-id');
    var registrants = sumRegistrants();
    if (registrants.length > 0) {
      if (confirm("If you proceed, this will send the webcast link to the selected " + registrants.length + " registrant(s) by email. Typically this is sent 24 hours before the start of the event. Please confirm if you wish to proceed.")) {
        $.ajax({
          url: '/events/' + event_id + '/send_webcast_link',
          type: "POST",
          data: {registrants},
          success: function (msg) {}
        });
      }
    }
  });

  //select all checkboxes
  $(".row_checkbox").click(function (e) {
    var $inputAll = $('input[name="registrant_id"]').length;
    if ($(this).attr("name") == "select_all") {
      if ($(this).is(":checked")) {
        $('input[name="registrant_id"]').each(function () {
          this.checked = true;
        });
      } else {
        $('input[name="registrant_id"]').each(function () {
          this.checked = false;
        });
      }
    } else if ($(this).attr("name") == "registrant_id") {
      var $countCheck = $('input[name="registrant_id"]:checked').length;
      if ($countCheck == $inputAll) {
        $('input[name="select_all"]').each(function () {
          this.checked = true;
        });
        $('input[name="select_all"]').prop("checked", true);
      } else {
        $('input[name="select_all"]').each(function () {
          this.checked = false;
        });
        $('input[name="select_all"]').prop("checked", false);
      }
    }
    batchBtnState();
  });
})
document.addEventListener("turbolinks:load", () => {
	$("select").not( ".include-blank" ).each(function(){
		if($(this).is(":visible")){
			$(this).children(":first-child").attr({
				"hidden": "hidden",
				"class": "d-none",
				"disabled": "disabled"
			});
		}
	});
})


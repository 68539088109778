// Error code: 980
// axios call to verify if email active to before let user submit form
//
// Error code: 970
// validation function error

import axios from 'axios'

const csrfToken = document.querySelector("meta[name=csrf-token]").content
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

// Email sigin in without requiring password
// This js will check if current email is valid and exists in database as
// one of the active email address. If yes, it will allow to submit form for
// next step verification of email presence on serverside
var EmailAuth;

const form = document.querySelector('#emailAuthForm')
const email = document.querySelector('#emailAuthField')

EmailAuth = {
    emailState: '',

    init: () => {
        email.onfocus = () => {
            EmailAuth.showSubmitButton()
            EmailAuth.clearError()
        }

        form.addEventListener("submit", (e) => {
          e.preventDefault();
          EmailAuth.submit();
        }, false);
    },

    showSubmitButton: () => {
        let inputs = form.elements
        inputs['commit'].disabled = false
    },

    // Listening for form submition
    // @return false
    submit: () => {
      var formData = new FormData(form)

      EmailAuth.emailState = formData.get('user[email]')

      if(EmailAuth.validation(EmailAuth.emailState,'email')) {
          EmailAuth.isEmailActive({'email': EmailAuth.emailState})
      } else if ($('input[name="recaptcha_token"]').length) {
        grecaptcha.reset();
      }
    },

    // Validation method
    // Validate :email or empty string
    // @param field: String, type: String
    // @return Bool true|false
    validation: (field, type) => {
        let valid = true
        try {
          if(!field || field.length <= 0) {
            valid = false
            EmailAuth.setError('Please Fill out This Field')
          }else if(type == 'email') {
            let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(pattern.test(String(field).toLowerCase()) === false) {
              valid = false
              EmailAuth.setError('Email should be valid')
              // EmailAuth.switchForm('email')
            }
          }
        } catch (e) {
          console.error(e)
          EmailAuth.setError('Something went wrong, please contact administrator. Error code: 970')
          valid = false
        }
        return valid
    },

    // Check if email active for current user
    // XHR and Fetch from backend server
    // @param formData Object {key: value}
    // @return Form submition or show error
    isEmailActive: (formData) => {
        let dataObj = {
            method: 'POST',
            url: '/check_email',
            data: formData
        }

        // Send request to the back end
        axios(dataObj)
            .then(response => {
                if(response.status == 200 && response.data.status == 'ok' && response.data.email == EmailAuth.emailState.toLowerCase()) {
                    form.submit()
                }else{
                    if ($('input[name="recaptcha_token"]').length) {
                      grecaptcha.reset();
                    }

                    EmailAuth.setError(response.data.error, 3600000)
                }
            })
            .catch(error => {
                if ($('input[name="recaptcha_token"]').length) {
                  grecaptcha.reset();
                }

                EmailAuth.setError('Something went wrong, please contact administrator Error code: 980')
            })
    },

    // Set error using modal window
    setError: (msg, timeout = 5000) => {
        EmailAuth.createErrorElement(msg, 'alert-danger')

        window.setTimeout(function() {
            EmailAuth.clearError()
        }, timeout)
    },

    // Clearing error html view
    clearError: () => {
        let toRemove = document.querySelector('#notice')
        if(toRemove) {
          toRemove.parentNode.removeChild(toRemove);
        }
    },

    // Generate Error html view
    // Will be triggered from setError('message') method
    createErrorElement: (msg, type) => {
        var body = document.querySelector('body')
        var notice = document.createElement('div')
        var alert = document.createElement('div')
        var icon = document.createElement('i')
        var button = document.createElement('button')
        var span = document.createElement('span')
        var text = document.createElement('span')

        notice.style.display = 'block'
        notice.setAttribute('id', 'notice')
        alert.setAttribute('role', 'alert')
        alert.className = 'alert '+type+' alert-dismissible fade show'
        icon.className = 'fas fa-exclamation-triangle'
        button.setAttribute('type', 'button')
        button.setAttribute('data-dismiss', 'alert')
        button.setAttribute('area-label', 'Close')
        button.className = 'close'
        span.setAttribute('aria-hidden','true')
        span.innerHTML = '&times;'
        text.innerHTML = msg

        body.appendChild(notice)
        notice.appendChild(alert)
        alert.appendChild(icon)
        alert.appendChild(text)
        alert.appendChild(button)
        button.appendChild(span)
    },

}

document.addEventListener("turbolinks:load", () => {
  if(form) {
    EmailAuth.init()
  }
})

var onLoginByEmail = function(response) {
  EmailAuth.submit();
}

export { onLoginByEmail }
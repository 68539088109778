require("packs/jquery.magnific-popup")
require("packs/smk-accordion")
// require("packs/pigeonhole")
// require("packs/webcast_QA_01")
// require("packs/webcast_QA_02")

document.addEventListener("turbolinks:load", () => {
  if($("body.webcast").length > 0 || $("body.preview_webcast").length > 0) {
    $('.open-popup-link').magnificPopup({
      type:'inline',
      midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });

    $(".accordion_bod").smk_Accordion({
      closeAble: true, //boolean
    });
  }

  if ($("iframe").length > 0) {
    $("iframe").on('load', function() {
      if ($('iframe').attr('src') && $('iframe').attr('src').includes("/rails/mailers")) {
        $(this).height( $(this).contents().find("body").find('iframe').contents().find("body").height() + 150 );
      }
    });
  }

  if ($("#si-player").length > 0){
    var PLAYBACK_URL = $("#event-attr").data('link');
    var player;
    (function play() {
      registerIVSTech(videojs);
      registerIVSQualityPlugin(videojs);
      player = videojs('si-player', {
        techOrder: ["AmazonIVS"],
        controlBar: {
          playToggle: {
            replay: false
          },
          pictureInPictureToggle: false
        }
      });
    })();

    function play_stream(){
      $.ajax({
        url: PLAYBACK_URL,
        type: 'GET',
        timeout: 9000,
        success: function () {
          player.enableIVSQualityPlugin();
          player.volume(0.5);
          player.src(PLAYBACK_URL);
        },
        error: function () {
          setTimeout(play_stream, 10000);
        },
        fail: function () {
          setTimeout(play_stream, 10000);
        },
      })
    }
    if (PLAYBACK_URL) {
      play_stream()
    }
  }

})
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("company_custom/custom")

import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")
require("formdata-polyfill")
require("packs/forms")
require("packs/notice")
require("packs/sign_in_method")
require("packs/no_password_sign_in")
require("packs/select")
require("packs/registrations")
require("packs/webcast_layout")
require("packs/pigeonhole")
require("packs/webcast_QA_01")
require("packs/downloads")
require("packs/dashboard")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import '../stylesheets/application'

import { onLoginByEmail } from './no_password_sign_in'
window.onLoginByEmail = onLoginByEmail

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()

  if ($("iframe")) {
    $("iframe").on('load', function() {
      if ($('iframe').attr('src') && $('iframe').attr('src').includes("/rails/mailers")) {
        $(this).height( $(this).contents().find("body").find('iframe').contents().find("body").height() + 150 );
      }
    });
  }
})
